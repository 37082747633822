import { faBell, faTruckFast, faCartFlatbed, faCartShopping, faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import uniqolor from "uniqolor";
import INotificationInfo from "../portal/notifications/interfaces/INotificationInfo";
import { v4 as uuidv4 } from "uuid";

export const GetOrderStatusEventIcon = (eventType: string) => {
  switch (eventType) {
    case "OrderCreated":
      return faCartShopping;
    case "OrderProcessed":
      return faCartFlatbed;
    case "OrderFulfilled":
      return faTruckFast;
    case "ShipmentPrepared":
      return faCartFlatbed;
    case "ShipmentProcessed":
      return faTruckLoading;
    default:
      return faBell;
  }
};

export const GetOrderStatusEventBackgroundColor = (eventInfo: INotificationInfo) => {
  switch (eventInfo.eventType) {
    case "OrderCreated":
    case "OrderProcessed":
    case "OrderFulfilled":
    default:
      return uniqolor(eventInfo.orderId, {
        saturation: [35, 70],
        lightness: [75, 90],
        differencePoint: 50,
      }).color;
    case "ShipmentPrepared":
    case "ShipmentProcessed":
      return uniqolor(eventInfo.shipmentId, {
        saturation: [35, 70],
        lightness: [75, 90],
        differencePoint: 50,
      }).color;
  }
};

export const GetOrderStatusEventFontColor = (eventInfo: INotificationInfo) => {
  switch (eventInfo.eventType) {
    case "OrderCreated":
    case "OrderProcessed":
    case "OrderFulfilled":
    default:
      return uniqolor(eventInfo.orderId).color;
    case "ShipmentPrepared":
    case "ShipmentProcessed":
      return uniqolor(eventInfo.shipmentId).color;
  }
};

export const PublishEventNotification = (eventInfo: INotificationInfo) => {
  const toastId = uuidv4();
  toast.info(eventInfo.message, {
    position: "bottom-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    toastId: toastId,
    progress: undefined,
    onOpen: () => {
      var toastContainer = document.getElementById(toastId);
      if (toastContainer) {
        toastContainer.style.backgroundColor = GetOrderStatusEventBackgroundColor(eventInfo);
      }
    },
    icon: ({ theme, type }) => (
      <FontAwesomeIcon
        className="notification-link-icon"
        icon={GetOrderStatusEventIcon(eventInfo.eventType)}
        color={GetOrderStatusEventFontColor(eventInfo)}
      />
    ),
  });
};

export const GetNiceEventMessage = (eventDetails: INotificationInfo) => {
  switch (eventDetails.eventType) {
    case "OrderCreated":
      return "We received your order, it is being processed.";
    case "OrderProcessed":
      return "We processed your order and will ship it to you soon.";
    case "OrderFulfilled":
      return "Your order has been fulfilled, we processed all order shipments.";
    case "ShipmentPrepared":
      return "We packing a part of your order and will ship it to you soon.";
    case "ShipmentProcessed":
      return "We shipped a part of your order, you can track it using provided tracking number.";
    default:
      return "Generic Notification";
  }
};

export const SetTimeout = async (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};
