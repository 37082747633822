import axios from "axios";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { StoreContext } from "./StoreContext";
import IProductInfo from "../portal/store/interfaces/IProductInfo";
import INotificationInfo from "../portal/notifications/interfaces/INotificationInfo";
import { PubSub, Auth, Hub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { ZenObservable } from "zen-observable-ts";
import { GetNiceEventMessage, PublishEventNotification, SetTimeout } from "../helpers/DisplayHelper";
import { GetPortalConfigurationFromEnvironmentFile } from "../helpers/ConfigurationHelper";

type Props = {
  children: React.ReactNode;
};

export const StoreContextProvider = ({ children }: Props) => {
  const [cartItems, setCartItems] = useState<IProductInfo[]>([]);
  const [notificationItems, setNotificationItems] = useState<INotificationInfo[]>([]);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [storeId, setStoreId] = useState("");
  const [notification, setNotification] = useState<INotificationInfo>();
  const [webSocketConnection, setWebSocketConnection] = useState<ZenObservable.Subscription>();
  const [webSocketSubscribed, setWebSocketSubscribed] = useState<Boolean>(false);
  const webSocketError = useRef<Boolean>(false);
  const userSignedUp = useRef<Boolean>(false);

  const httpClient = axios.create();
  const portalConfiguration = GetPortalConfigurationFromEnvironmentFile();

  httpClient.interceptors.request.use(async function (config: any) {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const token = await idToken.getJwtToken();
    config.headers.Authorization = token;
    return config;
  });

  const addItemToCart = useCallback(
    async (item: IProductInfo) => {
      try {
        //console.log(item);
        setCartItems([...cartItems, item]);
      } catch (error) {
        console.error(error);
      }
    },
    [cartItems]
  );

  const removeItemFromCart = useCallback(
    async (productId: string) => {
      var items = cartItems.filter((item: IProductInfo) => item.productId !== productId);
      setCartItems(items);
    },
    [cartItems]
  );

  const addNotification = useCallback(
    async (item: INotificationInfo) => {
      try {
        //console.log(item);
        //setNotificationItems([...notificationItems, item]);
        setNotificationItems([item].concat(notificationItems));
      } catch (error) {
        console.error(error);
      }
    },
    [notificationItems]
  );

  const clearCart = useCallback(async () => {
    try {
      setCartItems([]);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const clearNotifications = useCallback(async () => {
    try {
      setNotificationItems([]);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const applyUserName = async (enableWebSocketAccess: Boolean) => {
    try {
      Auth.currentAuthenticatedUser()
        .then(() => {
          Auth.currentUserInfo()
            .then(async (info: { attributes: { [x: string]: string } }) => {
              if (info.attributes) {
                if (enableWebSocketAccess) {
                  const credentials = await Auth.currentUserCredentials();
                  console.error(credentials.identityId);

                  const userInfo = {
                    userId: info.attributes.sub,
                    userName: userName,
                    userIdentityId: credentials.identityId,
                  };

                  const response = await httpClient.post(`/userSessions`, userInfo);
                  console.error(response);
                }

                setUserId(info.attributes.sub);
                if (info.attributes.given_name && info.attributes.family_name) {
                  setUserName(info.attributes.given_name + " " + info.attributes.family_name);
                } else {
                  setUserName("Guest User");
                }
              } else {
                console.error("User access disabled");
                setUserId("");
                setUserName("");
                Auth.signOut();
              }
            })
            .catch((err: any) => {
              console.error(err);
            });
        })
        .catch((err: any) => {
          // console.error(err);
          setUserId("");
          setUserName("");
        });
    } catch (error) {
      // console.error(error);
    }
  };

  useEffect(() => {
    async function initializeContext() {
      try {
        // console.info("Store Context Initialized");
        console.log("Context initializing");

        Hub.remove("auth", listener);
        Hub.listen("auth", listener);

        await applyUserName(false);

        await loadUserProfile();
      } catch (error) {
        console.error(error);
      }
    }

    initializeContext();

    return () => Hub.remove("auth", listener);
  }, []);

  const loadUserProfile = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const response = await httpClient.get(`/users`, {
        params: {
          userId: user.attributes.sub,
        },
      });
      //console.log(response.data);
      setStoreId(response.data.storeId ?? "");
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    if (notification) {
      addNotification(notification);
    }
  }, [notification]);

  const onOrderEvent = useCallback(
    (data: string) => {
      console.info(data);
      const messageData = JSON.parse(JSON.stringify(data));

      var eventInfo: INotificationInfo = {
        eventId: messageData.value.eventId,
        orderId: messageData.value.orderId,
        eventDate: Date.parse(messageData.value.eventDate),
        eventType: messageData.value.eventType,
        message: messageData.value.message,
        shipmentId: messageData.value.shipmentId,
        shipments: [],
      };

      const niceMessage = GetNiceEventMessage(eventInfo);

      eventInfo["message"] = niceMessage;

      setNotification(eventInfo);

      PublishEventNotification(eventInfo);

      // eventSwal.fire({
      //   position: "bottom-start",
      //   icon: "success",
      //   title: niceMessage,
      //   showConfirmButton: false,
      //   timer: 2000,
      //   toast: true,
      //   customClass: {
      //     container: "popup-custom-margin",
      //   },
      // });
    },
    [notificationItems]
  );

  function useCancelToken() {
    const token = useRef({ cancelled: false });
    const setCancelled = (status: boolean) => (token.current.cancelled = status);
    return [token.current, setCancelled] as const;
  }

  const [token, setCancelled] = useCancelToken();

  const SetTimeoutWithCancellation = async (delay: number, token: any) => {
    const iterations = delay / 100;
    for (let i = 0; i < iterations; i++) {
      await SetTimeout(100);
      if (token.cancelled) {
        throw new Error("Timeout got cancelled");
      }
    }
  };

  const checkWebSocketSubscription = async () => {
    try {
      console.log("Started WebSocket subscription check");
      setCancelled(false);
      await SetTimeoutWithCancellation(5000, token);
      if (webSocketError.current) {
        // console.error("Still no subscription");
        webSocketError.current = false;
        setWebSocketSubscribed(false);
        await checkWebSocketSubscription();
      } else {
        console.log("We got websocket subscription");
        setWebSocketSubscribed(true);
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const onIotError = (error: string) => {
    const errorDetails = JSON.parse(JSON.stringify(error));
    if (errorDetails.error !== undefined && errorDetails.error.errorCode === 8) {
      console.error(errorDetails.error.errorMessage);
    }
    webSocketError.current = true;
    setWebSocketSubscribed(false);
    //PubSub.removePluggable("AWSIoTProvider");
    setCancelled(true);
    establishConnection(2000);
  };

  async function establishConnection(delay: number) {
    await SetTimeout(delay);
    subscribeToIotTopic();
  }

  useEffect(() => {
    if (userId === "") return;

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //establishConnection(0);

        if (!webSocketConnection || webSocketConnection.closed) {
          establishConnection(0);
        } else {
          console.log("Connection for user " + userId + " has already established.");
        }
      })
      .catch((err) => {
        //console.error(err);
      });
  }, [userId]);

  const subscribeToIotTopic = () => {
    clearConnection();

    console.info("Connecting to IoT Core for user " + userId);

    const iotProvider = new AWSIoTProvider({
      // clientId: userId,
      aws_pubsub_region: portalConfiguration.PubSubRegion,
      aws_pubsub_endpoint: portalConfiguration.PubSubEndpoint,
    });

    PubSub.addPluggable(iotProvider);

    webSocketError.current = false;

    const connection = PubSub.subscribe("order-status/" + userId).subscribe({
      next: (data: any) => onOrderEvent(data),
      error: (error: string) => onIotError(error),
      complete: () => console.log("Done"),
    });

    setWebSocketConnection(connection);
    checkWebSocketSubscription();

    console.info("Connected to IoT Core for user " + userId);
    //console.log(webSocketSubscription.closed);
  };

  const clearConnection = () => {
    // Unsubscribe from WebSockets connection
    if (webSocketConnection) {
      //console.log("Existing connection closed?: " + webSocketConnection.closed);
      if (!webSocketConnection.closed) {
        console.log("Unsubscribing from existing WebSocket connection");
        webSocketConnection.unsubscribe();
      }
    }
    setWebSocketSubscribed(false);

    PubSub.removePluggable("AWSIoTProvider");
  };

  const listener = async (data: any) => {
    console.info(data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        if (userSignedUp.current) {
          console.log("Enabling WebSocket access for the user");
          userSignedUp.current = false;
          applyUserName(true);
        } else {
          applyUserName(false);
        }
        loadUserProfile();
        break;

      case "signOut":
        applyUserName(false);
        clearConnection();
        break;

      case "signUp":
        userSignedUp.current = true;
        break;
    }
  };

  return (
    <StoreContext.Provider
      value={{
        cartItems,
        notificationItems,
        addItemToCart,
        removeItemFromCart,
        clearCart,
        addNotification,
        clearNotifications,
        userId,
        userName,
        setUserName,
        clearConnection,
        storeId,
        webSocketSubscribed,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
