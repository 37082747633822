import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./OrderManager.css";
import Table from "react-bootstrap/Table";
import uniqolor from "uniqolor";
import IOrderInfo from "./interfaces/IOrderInfo";
import { Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import { faTruckFast, faCartFlatbed, faCartShopping, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import IOrderManager from "./interfaces/IOrderManager";

export default function OrderManager(props: IOrderManager): JSX.Element {
  const [orderList, setOrderList] = useState<IOrderInfo[]>([]);
  const [noData, setNoData] = useState<Boolean>(false);
  type OrderStatus = [string, IconDefinition];

  const httpClient = axios.create();

  httpClient.interceptors.request.use(async function (config: any) {
    const session = await Auth.currentSession();
    const idToken = await session.getIdToken();
    const token = await idToken.getJwtToken();
    config.headers.Authorization = token;
    return config;
  });

  function getOrderStatus(orderStatus: string): OrderStatus {
    switch (orderStatus) {
      case "CREATED":
        return ["Created", faCartShopping];
      case "PROCESSED":
        return ["Processed", faCartFlatbed];
      case "FULFILLED":
        return ["Fulfilled", faTruckFast];
    }
    return ["Not supported", faCartShopping];
  }

  useEffect(() => {
    async function getOrders() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const response = await httpClient.get(`/orders`, {
          params: {
            userId: user.attributes.sub,
          },
        });
        if (response.data.length > 0) {
          var orders = response.data.sort(function (a: IOrderInfo, b: IOrderInfo) {
            if (
              (a.orderProcessingDate ? a.orderProcessingDate : a.orderCreationDate) <
              (b.orderProcessingDate ? b.orderProcessingDate : b.orderCreationDate)
            )
              return 1;
            if (
              (a.orderProcessingDate ? a.orderProcessingDate : a.orderCreationDate) >
              (b.orderProcessingDate ? b.orderProcessingDate : b.orderCreationDate)
            )
              return -1;
            return 0;
          });
          setOrderList(orders);
          setNoData(false);
        } else {
          setNoData(true);
        }
        //console.log(response);
      } catch (error) {
        console.log(error);
      }
    }

    getOrders();
  }, []);

  return (
    <div className="orders-container">
      {orderList.length > 0 ? (
        <section className="orders-section">
          <span className="orders-title-label">Your Orders:</span>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Order Number</th>
                <th>Order Date</th>
                <th>Order Status</th>
              </tr>
            </thead>
            <tbody>
              {orderList.map((item) => {
                const status: OrderStatus = getOrderStatus(item.orderStatus);
                return (
                  <tr key={item.orderId}>
                    <td>
                      <Link className="orders-details-link" to={item.orderId} state={item.orderId}>
                        {item.orderId.substring(0, 18)}
                      </Link>
                    </td>
                    <td className="orders-table-date-column">
                      {new Intl.DateTimeFormat("us-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(Date.parse(item.orderProcessingDate ? item.orderProcessingDate : item.orderCreationDate))}
                    </td>
                    <td className="orders-table-cell-status-container">
                      <FontAwesomeIcon className="orders-table-cell-status-container-icon" icon={status[1]} color={uniqolor(item.orderId).color} />
                      <span
                        className="orders-table-cell-status-container-text"
                        style={{
                          color: uniqolor(item.orderId, {
                            saturation: 80,
                            lightness: 40,
                          }).color,
                        }}
                      >
                        {status[0]}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </section>
      ) : noData ? (
        <div className="orders-container">
          <section className="orders-section">
            <span className="orders-empty-orders">
              Your do not have any orders, it is time to{" "}
              <Link className="orders-buy-link" to="/">
                buy
              </Link>{" "}
              something!
            </span>
          </section>
        </div>
      ) : (
        <div className="store-container-empty">
          <Spinner className="store-spinner" animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
}
