import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import "./ProductRating.css";

interface IProductRating {
  productRating: number;
}

export default function ProductRating({ productRating }: IProductRating): JSX.Element {
  return (
    <div className="product-rating-container">
      {[...Array(Math.round(productRating))].map((e, i) => {
        return <FontAwesomeIcon key={i} className="product-rating-icon" icon={faStar} />;
      })}
      {Math.round(productRating % 1) === 0 && productRating % 1 !== 0 && <FontAwesomeIcon className="product-rating-icon" icon={faStarHalfAlt} />}
    </div>
  );
}
