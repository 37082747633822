import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { StoreContextProvider } from "./contexts/StoreContextProvider";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";

ReactDOM.render(
  <BrowserRouter>
    {/* <React.StrictMode> */}
    <StoreContextProvider>
      <App />
    </StoreContextProvider>
    {/* </React.StrictMode> */}
  </BrowserRouter>,
  document.getElementById("root")
);
