import "./AboutManager.css";
import IAboutManager from "./interfaces/IAboutManager";

export default function AboutManager(props: IAboutManager): JSX.Element {
  return (
    <div className="about-container">
      <div className="about-section">
        <div className="about-label">Have a feedback for this Live Demo? Found a bug?</div>
        <div className="about-message-container">
          <div className="about-label">Please reach out to</div>
          <div className="about-label-email">avvladi@</div>
        </div>
      </div>
      <div className="about-copyright">
        ©{" "}
        {new Intl.DateTimeFormat("us-US", {
          year: "numeric",
        }).format(Date.now())}
        , Amazon Web Services, Inc. or its affiliates. All rights reserved.
      </div>
    </div>
  );
}
