import React from "react";
import IProductInfo from "../portal/store/interfaces/IProductInfo";
import INotificationInfo from "../portal/notifications/interfaces/INotificationInfo";

type StoreContextType = {
  cartItems: IProductInfo[];
  notificationItems: INotificationInfo[];
  addItemToCart: (value: IProductInfo) => void;
  removeItemFromCart: (productId: string) => void;
  clearCart: () => void;
  addNotification: (value: INotificationInfo) => void;
  clearNotifications: () => void;
  userId: string;
  storeId: string;
  userName: string;
  setUserName: (value: string) => void;
  clearConnection: () => void;
  webSocketSubscribed: Boolean;
};

export const StoreContext = React.createContext<StoreContextType | undefined>(undefined);

export const useStoreContext = () => React.useContext(StoreContext);
