import axios from "axios";
import { Routes, Route } from "react-router-dom";
import StoreManager from "./portal/store/StoreManager";
import NavigationBar from "./portal/navigation/NavigationBar";
import ProfileManager from "./portal/profile/ProfileManager";
import CheckoutManager from "./portal/checkout/CheckoutManager";
import Amplify, { Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import NotificationManager from "./portal/notifications/NotificationManager";
import OrderManager from "./portal/orders/OrderManager";
import OrderDetailsManager from "./portal/orders/OrderDetailsManager";
import AboutManager from "./portal/about/AboutManager";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStoreContext } from "./contexts/StoreContext";
import { Spinner } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { SetTimeout } from "./helpers/DisplayHelper";
import { GetAmplifyConfigurationFromEnvironmentFile, GetPortalConfigurationFromEnvironmentFile } from "./helpers/ConfigurationHelper";

Amplify.configure(GetAmplifyConfigurationFromEnvironmentFile());

const portalConfiguration = GetPortalConfigurationFromEnvironmentFile();

document.title = portalConfiguration.PortalTitle;
axios.defaults.baseURL = portalConfiguration.ApiUrl;

const formFields = {
  signUp: {
    given_name: {
      placeholder: "First Name",
      order: 1,
    },
    family_name: {
      placeholder: "Last Name",
      order: 2,
    },
    email: {
      order: 3,
    },
    password: {
      order: 4,
    },
    confirm_password: {
      order: 5,
    },
  },
};

function App(): JSX.Element {
  const storeContext = useStoreContext()!;
  const [initializationMessage, setInitializationMessage] = useState("");

  const services = {
    async handleSignUp(formData: any) {
      let { username, password, attributes } = formData;

      // custom username
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      // const queryParams = new URLSearchParams(window.location.search);
      // const storeId = queryParams.get("storeId");
      // if (storeId) {
      //   attributes.website = storeId;
      // }
      // console.log(formData);
      return Auth.signUp({
        username,
        password,
        attributes,
      });
    },
  };
  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get("sessionId");

  // const onSignUp = async () => {
  //   try {
  //     console.log("Start Sign Up process");
  //     const response = await httpClient.get(`/userSessions`, {
  //       params: {
  //         sessionId: sessionId,
  //       },
  //     });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //   }
  // };

  const onSignIn = async () => {
    try {
      console.log("Start Sign In process");
      var httpClient = axios.create();
      var response = await httpClient.get(`/userSessions`, {
        params: {
          sessionId: sessionId,
        },
      });
      const userName = response.data;
      console.log(userName);

      const signInResponse = await Auth.signIn(userName);
      console.error(response);
      // const userAttributes = response as { attributes: { [x: string]: string } };
      httpClient = axios.create();

      httpClient.interceptors.request.use(async function (config: any) {
        const session = await Auth.currentSession();
        const idToken = await session.getIdToken();
        const token = await idToken.getJwtToken();
        config.headers.Authorization = token;
        return config;
      });

      const credentials = await Auth.currentUserCredentials();
      console.error(credentials.identityId);

      const userInfo = {
        userId: signInResponse.attributes.sub,
        userName: userName,
        userIdentityId: credentials.identityId,
      };

      response = await httpClient.post(`/userSessions`, userInfo);
      console.error(response);
    } catch (error) {
      console.error(error);
    }
  };

  const initializeGuestSession = async () => {
    try {
      try {
        await Auth.currentAuthenticatedUser();
        console.log("User session already exist, signing user out...");
        await Auth.signOut();
        console.log("User signed out");
      } catch (error) {
        console.error(error);
      }

      var httpClient = axios.create();

      console.log("Start Sign In process");
      var response = await httpClient.get(`/userSessions`, {
        params: {
          sessionId: sessionId,
        },
      });
      const userName = response.data;
      console.log(userName);

      const signInResponse = await Auth.signIn(userName);
      console.error(response);
      // const userAttributes = response as { attributes: { [x: string]: string } };
      httpClient = axios.create();

      httpClient.interceptors.request.use(async function (config: any) {
        const session = await Auth.currentSession();
        const idToken = await session.getIdToken();
        const token = await idToken.getJwtToken();
        config.headers.Authorization = token;
        return config;
      });

      const credentials = await Auth.currentUserCredentials();
      console.error(credentials.identityId);

      const userInfo = {
        userId: signInResponse.attributes.sub,
        userName: userName,
        userIdentityId: credentials.identityId,
      };

      response = await httpClient.post(`/userSessions`, userInfo);
      console.error(response);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const messageTimeout = 3000;

  const setMessage = async (message: string, delay: number) => {
    await SetTimeout(delay);
    setInitializationMessage(message);
  };

  useEffect(() => {
    async function initializeStoreMode() {
      try {
        console.log("Initializing Guest Mode");
        await setMessage("Validating User Access", 0);
        await setMessage("Creating User Account", messageTimeout);
        await setMessage("Checking Store Inventory", messageTimeout);
        await setMessage("Dusting Off Shelves", messageTimeout);
        await setMessage("Generating Promotions", messageTimeout);
        await setMessage("Opening Store Doors", messageTimeout);
        await setMessage("Applying Finishing Touches", messageTimeout);
      } catch (error) {
        console.error(error);
      }
    }

    if (sessionId) {
      initializeStoreMode();
      initializeGuestSession();
    }
  }, []);

  // var letterIndex = 0;

  const letterIndex = useRef<number>(0);
  var animationIndex = 0;

  return (
    <div>
      {!sessionId || storeContext.userId !== "" ? (
        // {!sessionId || (storeContext.userId !== "" && storeContext.webSocketSubscribed) ? (
        <div>
          <NavigationBar portalName={portalConfiguration.PortalTitle} />
          <main id="main" className="u-full-vw u-full-vh">
            <Authenticator
              className="data-amplify-authenticator"
              formFields={formFields}
              services={services}
              // initialState={sessionId ? "signUp" : "signIn"}
            >
              {() => (
                <Routes>
                  <Route path="/" element={<StoreManager />} />
                  <Route path="store" element={<StoreManager />} />
                  <Route path="orders/:orderId" element={<OrderDetailsManager />} />
                  <Route path="orders" element={<OrderManager />} />
                  <Route path="about" element={<AboutManager />} />
                  <Route path="cart" element={<CheckoutManager />} />
                  <Route path="profile" element={<ProfileManager />} />
                  <Route path="notifications" element={<NotificationManager />} />
                </Routes>
              )}
            </Authenticator>
          </main>
        </div>
      ) : (
        <div className="portal-container-empty">
          <Spinner className="portal-spinner" animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="portal-text-container">
            {initializationMessage.split(" ").map((word) => {
              animationIndex = 0;
              var result = Array.from(word).map((letter) => {
                letterIndex.current = letterIndex.current + 1;
                animationIndex = animationIndex + 1;

                return (
                  <div
                    key={letterIndex.current}
                    className="portal-text-label flip-animation"
                    style={{ "--animation-index": animationIndex } as React.CSSProperties}
                  >
                    {letter}
                  </div>
                );
              });

              letterIndex.current = letterIndex.current + 1;
              result.push(
                <span className="portal-text-label" key={letterIndex.current}>
                  &nbsp;&nbsp;
                </span>
              );

              return result;
            })}
          </div>
          {/* <button style={{ margin: "1vw" }} onClick={onSignUp}>
            Test SignUp
          </button> */}
          {/* <button style={{ margin: "1vw" }} onClick={onSignIn}>
            Test SignIn
          </button> */}
        </div>
      )}

      <ToastContainer
        position="bottom-left"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        limit={3}
      />
    </div>
  );
}

export default App;
