import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ProductRating from "./ProductRating";
import IProductCard from "../interfaces/IProductCard";
import "./ProductCard.css";

export default function ProductCard({ productInfo, onAddToCart }: IProductCard): JSX.Element {
  const onAddToCartHandler = async (event: React.ChangeEvent<any>) => {
    await onAddToCart(productInfo);
  };

  return (
    <div
      className="store-product-info"
      style={{
        backgroundImage: 'url("' + productInfo.productImageUrl + '")',
      }}
    >
      <div className="store-product-info-add-button" onClick={onAddToCartHandler}>
        <FontAwesomeIcon icon={faPlus} />
      </div>

      <div className="store-product-info-image" />
      <label className="store-product-info-name">{productInfo.productName}</label>
      <label className="store-product-info-description">{productInfo.productDescription}</label>
      <div className="store-product-info-footer">
        <label className="store-product-info-price">${productInfo.productPrice}</label>
        <ProductRating productRating={productInfo.productRating} />
      </div>
    </div>
  );
}
